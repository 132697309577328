<template>
  <ul>
    <template v-if="isUserGRC()">
      <template v-for="item in items">
        <component
          v-if="item.grcAllow"
          :is="resolveNavItemComponent(item)"
          :key="item.header || item.title"
          :item="item"
        />
      </template>
    </template>
    <template v-else-if="isUserVendor()">
      <template v-for="item in items">
        <component
          v-if="item.vendorAllow"
          :is="resolveNavItemComponent(item)"
          :key="item.header || item.title"
          :item="item"
        />
      </template>
    </template>
    <template v-else>
      <template v-for="item in items">
        <component
          v-if="!(item.onlyVendor||item.onlyGRC)"
          :is="resolveNavItemComponent(item)"
          :key="item.header || item.title"
          :item="item"
        />
      </template>
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
  methods: {
    isUserVendor() {
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "Vendor"
      ) {
        return true;
      }
      return false;
    },
    isUserGRC(){
      if (
        this.$store.state.app.user.user_type &&
        this.$store.state.app.user.user_type == "GRC_user"
      ) {
        return true;
      }
      return false;
    }
  },
};
</script>
