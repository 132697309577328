const developmentRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    // vendorAllow: true,
  },
  {
    title: "Third Parties",
    route: "thirdparties",
    icon: "AlertOctagonIcon",
  },

    {
      title: "Risks",
      route: "responses",
      icon: "CheckSquareIcon",
    },
  {
    title: "Third Parties R.A",
    route: "thirdpartyrisksRA-assessments",
    icon: "AlertOctagonIcon",
  },
  {
    header: "Settings",
    icon: "SettingsIcon",
  },

  {
    title: "Questionnaires",
    route: "questionnaires",
    icon: "HelpCircleIcon",
  },
  {
    title: "Policies",
    route: "policies",
    icon: "FileTextIcon",
  },
  {
    title: "Assessments",
    route: "vendorassessments",
    icon: "AlertOctagonIcon",
    vendorAllow: true,
    onlyVendor: true,
  },
  {
    title: "Tasks",
    route: "tasks",
    icon: "CheckSquareIcon",
    grcAllow: true,
    vendorAllow: true,
  },
  {
    title: "Teams",
    route: "teams",
    icon: "UsersIcon",
  },
];

const productionRoutes = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "PieChartIcon",
    // vendorAllow: true,
  },
  {
    title: "Third Parties",
    route: "thirdparties",
    icon: "AlertOctagonIcon",
  },
  {
    title: "Risks",
    route: "responses",
    icon: "CheckSquareIcon",
  },
  {
    title: "Third Parties R.A",
    route: "thirdpartyrisksRA-assessments",
    icon: "AlertOctagonIcon",
  },
  {
    header: "Settings",
    icon: "SettingsIcon",
  },

  {
    title: "Questionnaires",
    route: "questionnaires",
    icon: "HelpCircleIcon",
  },
  {
    title: "Policies",
    route: "policies",
    icon: "FileTextIcon",
  },
  {
    title: "Assessments",
    route: "vendorassessments",
    icon: "AlertOctagonIcon",
    vendorAllow: true,
    onlyVendor: true,
  },
  {
    title: "Tasks",
    route: "tasks",
    icon: "CheckSquareIcon",
    grcAllow: true,
    vendorAllow: true,
  },
  {
    title: "Teams",
    route: "teams",
    icon: "UsersIcon",
  },
];

export default process.env.VUE_APP_ENVIRON &&
process.env.VUE_APP_ENVIRON === "DEVELOPMENT"
  ? developmentRoutes
  : productionRoutes;
