<template>
  <b-nav-item-dropdown
    ref="notificationDropdown"
    class="dropdown-notification ml-25"
    menu-class="dropdown-menu-media"
    left
  >
    <template #button-content>
      <feather-icon
        :badge="newNotifications.length"
        badge-classes="bg-danger"
        class="text-primary"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header border-bottom-0">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-button size="sm" variant="flat-primary" @click="handleViewMoreClick">
          <span class="align-middle">See more</span
          ><feather-icon icon="ExternalLinkIcon" class="ml-50" />
        </b-button>
      </div>
    </li>

    <!-- Notifications -->
    <div class="px-1">
      <b-tabs v-model="tabIndex" align="left">
        <b-tab title="View All" />
        <b-tab>
          <template slot="title">
            Unread
            <b-badge size="sm" pill variant="primary">{{
              newNotifications.length
            }}</b-badge>
          </template>
        </b-tab>
      </b-tabs>
    </div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      style="max-height: 50vh"
      tagname="li"
    >
      <template v-if="tabIndex == 0">
        <notification-list
          v-for="notification in notifications"
          :key="notification.notification_id"
          :notification="notification"
          :handleNotificationClick="handleNotificationClick"
          :closeNotificationDropdown="closeNotificationDropdown"
        />
      </template>

      <template v-if="tabIndex == 1">
        <notification-list
          v-for="notification in newNotifications"
          :key="notification.notification_id"
          :notification="notification"
          :handleNotificationClick="handleNotificationClick"
          :closeNotificationDropdown="closeNotificationDropdown"
        />
      </template>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="handleMarkAllNotificationAsReadClick"
      >
        <b-spinner
          small
          class="mr-50"
          variant="light"
          v-if="markAllReadSpinner"
        />
        <feather-icon v-else icon="CheckCircleIcon" class="mr-50" />
        <span class="align-middle">Mark all read</span>
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BTabs,
  BTab,
  BSpinner,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import NotificationList from "./NotificationList.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import NotificationMixins from "@/mixins/NotificationMixins";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BTabs,
    BTab,
    NotificationList,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [ResponseMixins, NotificationMixins],
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
  data() {
    return {
      tabIndex: 0,
      markAllReadSpinner: false,
    };
  },

  watch: {
    tabIndex(newValue) {
      console.log("Tab Index", newValue);
    },
  },

  mounted() {},
  computed: {
    newNotifications() {
      return this.notifications.filter(
        (notification) => notification.read_at == null
      );
    },
    readNotifications() {
      return this.notifications.filter(
        (notification) => notification.read_at != null
      );
    },
  },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    handleNotificationClick: {
      type: Function,
      required: true,
    },
    handleMarkAllNotificationAsRead: {
      type: Function,
      required: true,
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  methods: {
    handleMarkAllNotificationAsReadClick() {
      this.markAllReadSpinner = true;
      this.$store
        .dispatch("notification/markAllNotificationsAsRead")
        .then((res) => {
          this.$store.dispatch("notification/getAllNotifications");
          this.handleResponse(res);
        })
        .finally(() => {
          this.markAllReadSpinner = false;
        });
    },

    handleViewMoreClick() {
      this.closeNotificationDropdown();
      this.$router.push("/inbox");
    },

    closeNotificationDropdown() {
      this.$refs.notificationDropdown.hide();
    },
    getIconFromNotificationType(type) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (type) {
        case `REPORT`:
          return "BookIcon";
        case "TASK":
          return "BookIcon";
        case "SCAN":
          return "BookIcon";
        case "VULN":
          return "BookIcon";
        case "BASELINE":
          return "BookIcon";
        case "DOMAINSCAN":
          return "BookIcon";
        default:
          return "BookIcon";
      }
    },
    getTextFromNotificationType(type) {
      //VULN,TASK,SCAN,REPORT,BASELINE,DOMAINSCAN
      switch (type) {
        case `REPORT`:
          return "Report";
        case "TASK":
          return "Task";
        case "SCAN":
          return "Scan";
        case "VULN":
          return "Vulnerabilities";
        case "BASELINE":
          return "Baseline";
        case "DOMAINSCAN":
          return "Domain Scan";
        default:
          return "Unknown";
      }
    },
    
  },
};
</script>

<style scoped></style>
