<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <AppSwitcher class="d-none d-lg-block" />
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown
        class="d-none d-lg-block"
        :notifications="notifications"
        :handleNotificationClick="handleNotificationClick"
        :handleMarkAllNotificationAsRead="handleMarkAllNotificationAsRead"
      />
      <div class="ml-1">
        <b-form-select
          v-model="selectedWorkspace"
          @change="handleTenantChange(selectedWorkspace)"
          :options="workspaceOptions"
          style="width: 240px"
          placeholder="Select Workspace"
        />
      </div>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.app.user.firstname }}
              {{ $store.state.app.user.lastname }}
            </p>
            <span
              class="user-status d-flex flex-row align-items-center justify-content-end position-relative"
              v-if="$store.state.app.user['roles']"
            >
              <span v-if="$store.state.app.user.roles.length">{{
                $store.state.app.user.roles[0].name
              }}</span>
              <span>(</span>
              <template v-for="(value, key) in $store.state.app.workspaces">
                <span
                  :key="key"
                  style="max-width: 140px"
                  v-if="value.tenant_uid == $store.state.app.tenantId"
                >
                  <div class="text-container">
                    {{ value.name }}
                  </div>
                </span>
              </template>

              <span>)</span>
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            :text="avatarText($store.state.app.user.firstname)"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="handleLogout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <div
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      ></div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BFormSelect,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import NotificationDropdown from "@core/components/NotificationDropdown.vue"
import NotificationMixins from "@/mixins/NotificationMixins";
import AppSwitcher from "@/components/AppSwitcher.vue";
import { avatarText } from "@core/utils/filter";
import notification from "../../store/notification";

export default {
  data() {
    return {
      user: null,
      selectedWorkspace: Object.keys(this.$store.state.app.currentWorkspace).filter(objKey =>
            objKey !== 'onboardning_status').reduce((newObj, key) =>
            {
                newObj[key] = this.$store.state.app.currentWorkspace[key];
                return newObj;
            }, {})
            };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BFormSelect,

    // Navbar Components
    DarkToggler,
    AppSwitcher,
    NotificationDropdown
  },
  mixins:[NotificationMixins],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    workspaceOptions() {
      return this.$store.state.app.workspaces.map((wsp) => {
        return {
          text: wsp.name,
          value: wsp,
        };
      });
    },
    notifications(){
      return this.$store.state.notification.allNotifications;
    },

    ...mapState(["tenantid"]),
  },
  mounted(){
    this.$store.dispatch("notification/getAllNotifications");
      let channel = `grc_${this.$store.state.app.currentWorkspace.tenant_uid}_user_${this.$store.state.app.user.user_id}`;
      console.log("Socket Channel:", channel);
      this.sockets.subscribe(channel, (data) => {
        this.$store.dispatch("notification/getAllNotifications", data);
      });
  },
  beforeDestroy() {
    let channel = `grc_${this.$store.state.app.currentWorkspace.tenant_uid}_user_${this.$store.state.app.user.user_id}`;
    this.sockets.unsubscribe(channel);
    console.log("Un-Subscribed from channel", channel);
  },

  setup() {
    return {
      avatarText,
    };
  },
  methods: {
    handleTenantChange(workspace) {
      // console.log(workspace)
      // debugger
      this.changeTenant(workspace);
    },

    ...mapActions(["changetenant"]),
    handleNotificationClick(notification) {
      this.$store
        .dispatch("notification/getNotification", notification._id)
        .then((res) => {
          const newRoute = this.getRouteFromNotificationType(
            notification.data.target_type,
            notification.data.target_id
          );
          if (newRoute.includes(this.$route.path)) {
            this.$router.push(newRoute).then(() => {
              window.location.reload();
            });
          } else {
            this.$router.push(newRoute);
          }
          this.$store
            .dispatch("notification/getAllNotifications", {})
            .then(() => {
              this.$store.dispatch("notification/getNotifications", {
                page: 1,
              });
            });
        })
        .catch((err) => {
          // this.handleError(err);
          console.log(err);
        });
    },
    handleMarkAllNotificationAsRead() {
      this.$store
        .dispatch("notification/markAllNotificationsAsRead")
        .then((res) => {
          this.$store
            .dispatch("notification/getAllNotifications", {})
            .then(() => {
              this.$store.dispatch("notification/getNotifications", {});
            });
        })
        .catch((err) => {
          // this.handleError(err);
          console.log(err);
        });
    },
    handleLogout() {
      this.$store.dispatch("app/logout").then(() => {
        const url = "/login";
        // if (this.$store.state.app.user.is_staff) url = '/organizations'
        this.$router.replace(url);
        console.log("Logged Out");
      });
    },

    changeTenant(payload) {
      this.$store.dispatch("app/changetenant", payload).then(
        (response) => {
          window.location.reload();
        },
        (error) => {
          console.log("Something went wrong");
        }
      );
    },
  },
};
</script>
